import React from 'react';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CircularProgress, Grid } from "@mui/material";
import { Add, AddCircleOutlineTwoTone, Delete } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import ModalMobile from "../Modal";

export default function UsuarioCard({
  object,
  handleUpdate,
  handleConfirm,
  handleDelete,
  cargando,
}) {
  console.log("esta es la data", object);

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      style={{ alignContent: "baseline", marginTop: 5 }}
      spacing={3}
    >
      <Grid item xs={11}>
        <Card>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "#1976d2",
              marginBottom: "15px",
            }}
          >
            Usuarios
          </Typography>
          <CardActions
            style={{
              flexDirection: "row-reverse",
              justifyContent: "space-around",
            }}
          >
            <ModalMobile
              accion={handleConfirm}
              color={"success"}
              object={{}}
              startIcon={<AddCircleOutlineTwoTone />}
              label={"Agregar"}
            ></ModalMobile>
          </CardActions>
        </Card>
      </Grid>

      {!cargando ? (
        <div style={{ marginTop: "30%" }}>
          <Typography style={{ marginTop: "14px" }}>
            Aguarde unos segundos ...
          </Typography>
          <CircularProgress />
        </div>
      ) : (
        object?.map((d, i) => (
          <Grid key={i} item xs={11}>
            <Card>
              <CardContent style={{ textAlign: "justify" }}>
                <Typography variant="body2" color="text.secondary">
                  <b> Nombre : </b>
                  {d.user}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <b> Password :</b> {d.password}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <b> Rol :</b> {d.role}
                </Typography>
              </CardContent>
              <CardActions style={{ flexDirection: "row-reverse" }}>
                {d.activo && (
                  <ModalMobile
                    accion={handleUpdate}
                    object={d}
                    startIcon={<Edit />}
                    label={"Editar"}
                  ></ModalMobile>
                )}

                <Button
                  id={d.id}
                  onClick={() => handleDelete(d)}
                  style={{ marginRight: 10 }}
                  variant="outlined"
                  size="small"
                  color={d.activo === true ? "error" : "success"}
                  startIcon={d.activo === true ? <Delete /> : <Add />}
                >
                  {d.activo === true ? "Dar Baja" : "Restablecer"}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  );
}
