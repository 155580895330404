import React, { useEffect, useState } from "react";
import DragDrop from "./DragDrop";
import Zones from "./Zones";

export default function Almacen() {
  const almacen = [
    {
      id: 1,
      name: "Almacen 1",
      height: 12.8,
      width: 12.3,
      depth: 123,
      user_id: 1,
      zones: [
        {
          id: 1,
          name: "Zona 1",
          height: 12,
          width: 12,
          depth: 123,
          halls: [
            {
              id: 1,
              name: "Halls 1",
              height: 12,
              width: 12,
              depth: 123,
              racks: [
                {
                  id: 1,
                  name: "Rack 1",
                  height: 12,
                  width: 12,
                  depth: 123,
                  levels: [
                    {
                      id: 1,
                      name: "Level 1",
                      height: 12,
                      width: 12,
                      depth: 123,
                      sublevels: [
                        {
                          id: 1,
                          name: "Sublevel 1",
                          height: 12,
                          width: 12,
                          depth: 123,
                        },
                        {
                          id: 2,
                          name: "Sublevel 2",
                          height: 12,
                          width: 12,
                          depth: 123,
                        },
                      ],
                    },
                    {
                      id: 2,
                      name: "Level 2",
                      height: 12,
                      width: 12,
                      depth: 123,
                      sublevels: [
                        {
                          id: 1,
                          name: "abc",
                          height: 12,
                          width: 12,
                          depth: 123,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 2,
                  name: "Rack 2",
                  height: 12,
                  width: 12,
                  depth: 123,
                  levels: [
                    {
                      id: 1,
                      name: "abc",
                      height: 12,
                      width: 12,
                      depth: 123,
                      sublevels: [
                        {
                          id: 1,
                          name: "abc",
                          height: 12,
                          width: 12,
                          depth: 123,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              name: "Halls 2",
              height: 12,
              width: 12,
              depth: 123,
              racks: [
                {
                  id: 1,
                  name: "abc",
                  height: 12,
                  width: 12,
                  depth: 123,
                  levels: [
                    {
                      id: 1,
                      name: "abc",
                      height: 12,
                      width: 12,
                      depth: 123,
                      sublevels: [
                        {
                          id: 1,
                          name: "abc",
                          height: 12,
                          width: 12,
                          depth: 123,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 2,
          name: "Zona 2",
          height: 12,
          width: 12,
          depth: 123,
          halls: [
            {
              id: 1,
              name: "abc",
              height: 12,
              width: 12,
              depth: 123,
              racks: [
                {
                  id: 1,
                  name: "abc",
                  height: 12,
                  width: 12,
                  depth: 123,
                  levels: [
                    {
                      id: 1,
                      name: "abc",
                      height: 12,
                      width: 12,
                      depth: 123,
                      sublevels: [
                        {
                          id: 1,
                          name: "abc",
                          height: 12,
                          width: 12,
                          depth: 123,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Almacen 2",
      height: 12,
      width: 12,
      depth: 123,
      user_id: 1,
      zones: [
        {
          id: 1,
          name: "Zona 1",
          height: 12,
          width: 12,
          depth: 123,
          halls: [
            {
              id: 1,
              name: "Pasillo 1",
              height: 12,
              width: 12,
              depth: 123,
              racks: [
                {
                  id: 1,
                  name: "Rack 1",
                  height: 12,
                  width: 12,
                  depth: 123,
                  levels: [
                    {
                      id: 1,
                      name: "Nivel 1",
                      height: 12,
                      width: 12,
                      depth: 123,
                      sublevels: [
                        {
                          id: 1,
                          name: "Subnivel 1",
                          height: 12,
                          width: 12,
                          depth: 123,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const [almacenes, setAlmacenes] = useState([]);

  useEffect(() => {
    setAlmacenes(
      almacen.map((almacen) => {
        return {
          id: almacen.id.toString(),
          content: <Zones almacen={almacen} />,
        };
      })
    );
  }, []);

  return <DragDrop items={almacenes} setItems={setAlmacenes} />;
}
