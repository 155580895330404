import React from 'react';
import { useState, useEffect } from "react";
import Services from "../../../services/services";
import { Alert, Button, Grid, Snackbar, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import * as styles from "./styles";
import CustomMaterialTable from "../../../components/MaterialTable";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomModal from "../../../components/customModal";
import ModalIngreso from "./modalIngreso";
import { Link } from "react-router-dom";

const initialAlert = {
  severity: "success",
  message: "",
};

export default function CrearIngreso() {
  const [productos, setProductos] = useState([]);
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const [textoIngresado, setTextoIngresado] = useState("");
  const [productosSeleccionados, setProductosSeleccionados] = useState([]);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [openModal, setOpenMOdal] = useState(false);
  const [totalUnidadesIngresadas, setTotalUnidadesIngresadas] = useState(0);
  const [cantidadIngresadaErrors, setCantidadIngresadaErrors] = useState({});
  const [inputFocus, setInputFocus] = useState(null);

  const columns = [
    {
      title: "sku",
      field: "sku",
    },
    {
      title: "Descripcion",
      field: "nombre",
      maxWidth: 100,
    },
    {
      title: "Stock actual",
      field: "stockDisponible",
      minWidth: 150,
    },
    {
      title: "Stock a ingresar",
      field: "cantidadIngresada",
      minWidth: 200,
      render: (rowData) => (
        <TextField
          variant="outlined"
          key={rowData?.sku}
          size="small"
          type="text"
          style={{
            width: "80px",
          }}
          value={
            rowData.cantidadIngresada === null ? "" : rowData.cantidadIngresada
          }
          onChange={(event) => handleQuantityChange(event, rowData.sku)}
          inputProps={{
            pattern: "[0-9]*", // Solo permite números
            title: "Ingrese solo números",
          }}
          autoFocus={inputFocus === rowData.sku}
        />
      ),
    },
    {
      title: "Acciones",
      field: "acciones",
      render: (rowData) => (
        <DeleteIcon
          variant="outlined"
          color="red"
          onClick={() => handleRemoveProduct(rowData.sku)}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];
  useEffect(() => {
    // Cargar todos los productos al iniciar el componente
    handleSearch();
  }, []);

  useEffect(() => {
    // Filtrar productos al cambiar el texto ingresado
    filterProductsByText(textoIngresado);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textoIngresado, productos]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setTextoIngresado(inputValue);
  };

  const handleSearch = async () => {
    setIsSearching(true);
    try {
      const result = await Services.getProductosBySku("");
      console.log("todos los productos", result);
      if (result.data.Productos) {
        setProductos(result?.data?.Productos);
        setProductosFiltrados(result?.data?.Productos);
      } else {
        setProductos([]);
        setProductosFiltrados([]);
      }
    } catch (error) {
      console.error("Error al cargar productos:", error);
      setProductos([]);
      setProductosFiltrados([]);
    } finally {
      setIsSearching(false);
    }
  };

  const filterProductsByText = (text) => {
    const filteredProducts = productos.filter(
      (producto) =>
        producto.nombre.toLowerCase().includes(text.toLowerCase()) ||
        producto.sku.toLowerCase().includes(text.toLowerCase())
    );
    setProductosFiltrados(filteredProducts);
  };

  const handleProductSelect = (event, value) => {
    if (value) {
      // Verificar si el producto ya está en la lista
      const existentProduct = productosSeleccionados.find(
        (selectedProduct) => selectedProduct.sku === value.sku
      );

      if (!existentProduct) {
        // Si no existe, agregar el producto con cantidad ingresada null
        setProductosSeleccionados((prevProductos) => [
          { ...value, cantidadIngresada: null },
          ...prevProductos,
        ]);
      }
    }
    setTextoIngresado("");
  };

  const handleQuantityChange = (event, sku) => {
    const value = event.target.value.trim();
    const errors = { ...cantidadIngresadaErrors };

    if (value === "" || (!isNaN(value) && parseInt(value, 10) >= 0)) {
      errors[sku] = "";
      setProductosSeleccionados((prevProductos) =>
        prevProductos.map((producto) =>
          producto.sku === sku
            ? {
                ...producto,
                cantidadIngresada: value === "" ? null : parseInt(value, 10),
              }
            : producto
        )
      );
    } else {
      errors[sku] = "Campo requerido";
    }

    setCantidadIngresadaErrors(errors);
    setInputFocus(sku);
  };

  const handleRemoveProduct = (sku) => {
    setProductosSeleccionados((prevProductos) =>
      prevProductos.filter((product) => product.sku !== sku)
    );
    if (productosSeleccionados.length === 1) {
      handleCloseModal();
    }
  };

  const canConfirm = () => {
    const hasSelectedProducts = productosSeleccionados.length > 0;
    const hasInvalidQuantity = productosSeleccionados.some(
      (producto) =>
        !producto.cantidadIngresada || producto.cantidadIngresada <= 0
    );

    return hasSelectedProducts && !hasInvalidQuantity;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleOpenModal = () => setOpenMOdal(true);
  const handleCloseModal = () => setOpenMOdal(false);

  const calcularTotalUnidadesIngresadas = () => {
    const total = productosSeleccionados.reduce(
      (accumulator, producto) =>
        accumulator + (producto.cantidadIngresada || 0),
      0
    );
    setTotalUnidadesIngresadas(total);
  };

  useEffect(() => {
    calcularTotalUnidadesIngresadas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productosSeleccionados]);

  return (
    <styles.RootContainer>
      <Grid
        container
        item
        xs={8}
        justifyContent={"center"}
        style={{ alignContent: "baseline" }}
      >
        <Grid
          container
          item
          xs={12}
          style={{ marginTop: "2vh" }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid xs={8} style={{ textAlign: "start" }}>
            <h1 style={{ color: "#1976d2" }}>Nuevo ingreso</h1>
          </Grid>
          <Grid xs={4} style={{ textAlign: "end" }}>
            <Link to="/listado-ingresos">
              <Button variant="contained" color="primary">
                Listado Ingresos
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ height: "fit-content" }}>
          <Grid item xs={8}>
            <Autocomplete
              options={productosFiltrados}
              getOptionLabel={(option) =>
                `sku: ${option.sku}, nombre: ${option.nombre}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar productos"
                  variant="outlined"
                  onChange={(e) => handleInputChange(e)}
                />
              )}
              onChange={(event, value) => handleProductSelect(event, value)}
              value={null}
              noOptionsText={isSearching ? "Buscando..." : "No hay resultados"}
              onInputChange={(event, newInputValue) =>
                setTextoIngresado(newInputValue)
              }
            />
          </Grid>

          <Grid container item xs={4} justifyContent="end" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              disabled={!canConfirm()}
            >
              Crear ingreso
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <CustomMaterialTable
            title="Productos agregados"
            data={productosSeleccionados}
            setData={(x) => setProductosSeleccionados(x)}
            columns={columns}
            style={{ width: "100%", marginTop: "20px" }}
            mensajeDataArrayVacio="No hay productos agregados"
          />
        </Grid>
      </Grid>
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        title={"Confirmar el ingreso"}
      >
        <ModalIngreso
          totalUnidadesIngresadas={totalUnidadesIngresadas}
          productosSeleccionados={productosSeleccionados}
          handleRemoveProduct={handleRemoveProduct}
          cantidadIngresadaErrors={cantidadIngresadaErrors}
          setAlertObj={(x) => setAlertObj(x)}
          setOpen={(x) => setOpen(x)}
          setProductosSeleccionados={(x) => setProductosSeleccionados(x)}
          handleCloseModal={handleCloseModal}
          canConfirm={canConfirm}
        />
      </CustomModal>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertObj?.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
    </styles.RootContainer>
  );
}
