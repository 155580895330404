import {
    Alert,
    Button,
    Grid,
    Snackbar,
    TextField,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import Services from "../../services/services";
import * as React from "react";

const initialAlert = {
    severity: "",
    message: "",
};

const SeguirPedido = () => {
    // const [loading, setLoading] = useState(false);
    const [seguimiento, setSeguimiento] = useState([]);
    const [alertObj, setAlertObj] = useState(initialAlert);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [codigoSeguimiento, setCodigoSeguimiento] = useState("");
    const [intervalo, setIntervalo] = useState([]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        if (id) {
            hadleGetSeguimiento(id);
        }
    }, [])

    const handleCloseSnackBar = (event, reason) => {
        if (reason !== "clickaway") {
            setOpenSnackBar(false);
        }
    };

    const hadleGetSeguimiento = (codigo) => {
        setSeguimiento([]);
        setIntervalo([]);
        // setLoading(true);
        Services.getSeguimiento({codigo: codigo})
            .then((res) => {
                setSeguimiento(res.data);
                setIntervalo([res.desde, res.hasta]);
                initialAlert.severity = "success";
                initialAlert.message = "Seguimiento obtenido correctamente";
                setOpenSnackBar(true);
            })
            .catch((error) => {
                initialAlert.severity = "error";
                initialAlert.message = "El seguimiento no pudo ser obtenido";
                setOpenSnackBar(true);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            xs={12}
        >

            <Grid item xs={12}>
                <h1>Seguimiento de pedidos</h1>
            </Grid>
            <Grid
                item
                container
                flexDirection="column"
                xs={8}
                md={4}
                style={{
                    paddingLeft: "0px",
                    gap: "20px"}}>

                <Grid item
                      container
                      flexDirection="column"
                      xs={12}
                      style={{
                          paddingLeft: "0px",
                          gap: "10px"}}>
                    <TextField
                        label="Buscar por ID de pedido"
                        variant="outlined"
                        value={codigoSeguimiento}
                        onChange={(e) => setCodigoSeguimiento(e.target.value)}
                        fullWidth
                    />
                    <Grid
                        item
                        xs={12}
                        alignSelf="flex-end">

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => hadleGetSeguimiento(codigoSeguimiento)}>
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
                {intervalo.length > 0 && (
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ marginBottom: "2vh", width: '100%', textAlign: "center" }}
                    >
                        Llegara a destino entre el {intervalo[0]} y el {intervalo[1]}
                    </Typography>
                )}
                {seguimiento.length > 0 && (
                    <Stepper
                        orientation="vertical"
                        activeStep={seguimiento.findIndex(seg => seg.fecha !== undefined)}
                        xs={12}
                        style={{border: "1px solid #e0e0e0", borderRadius: "5px", padding: "40px"}}>
                        {seguimiento.map((seg, index) => (
                            <Step key={index} completed={seg.fecha !== undefined}>
                                <StepLabel>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                        <span style={{ fontSize: '1rem' }}>{seg.estado}</span>
                                        <span style={{ fontSize: '0.8rem', color: 'gray' }}>{seg.fecha ? seg.fecha : "Pendiente"}</span>
                                    </div>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                )}
            </Grid>
            <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert
                    onClose={handleCloseSnackBar}
                    severity={alertObj?.severity}
                    sx={{ width: "100%" }}
                >
                    {alertObj.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}

export default SeguirPedido;