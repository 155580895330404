// Logo.js
import React from 'react';
import miLogo from '../../assets/logo.jpeg';
import { styled } from '@mui/system';
const LogoImage = styled('img')({
    width: '50%',
    height: '50%', // You can use theme.spacing to get consistent spacing
  });

const Logo = () => {

  return (

    <LogoImage src={miLogo} alt="Mi Logo" />
  );
};

export default Logo;