import React from 'react';
import { Button, Grid } from "@mui/material";
import TablaInventario from "./tablaInventario";
import CustomModal from "../../components/customModal";
import { useState } from "react";
import CrearProducto from "./productos/crearproducto";
import HistorialModal from "./historial/HistorialModal";

const Inventario = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalHistorialOpen, setModalHistorialOpen] = useState(false);
  const [productoCreado, setProductoCreado] = useState(0);
  const [productoSKU, setProductoSKU] = useState("");

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  function handleOpenModalEdit(productoSKU) {
    setProductoSKU(productoSKU);
    setModalOpen(true);
  }

  function handleOpenModalHistorial(productoSKU) {
    setProductoSKU(productoSKU);
    setModalHistorialOpen(true);
  }

  function hadnleCloseModalEdit() {
    setProductoSKU();
    setModalOpen(false);
  }

  const handleCreateProducto = () => {
    setProductoCreado(productoCreado + 1);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} sm={10} sx={{ marginTop: "2vh", textAlign: "end" }}>
          <Button variant="contained" onClick={handleOpenModal}>
            Crear producto
          </Button>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TablaInventario productoCreado={productoCreado} handleOpenModalEdit={handleOpenModalEdit} handleOpenModalHistorial={handleOpenModalHistorial} />
        </Grid>
      </Grid>
      <HistorialModal
        open={modalHistorialOpen}
        setOpen={setModalHistorialOpen}
        productoSKU={productoSKU}
      />

      <CustomModal
        open={modalOpen}
        onClose={productoSKU ? hadnleCloseModalEdit : handleCloseModal}
        title={productoSKU ? "Editar Producto" : "Crear Producto"}
        sx={{
          width: { xs: "90%", sm: "70%", md: "50%" },
          mx: "auto",
          mt: "5vh",
        }}
      >
        <CrearProducto handleCreateProducto={handleCreateProducto} productoSKU={productoSKU} />
      </CustomModal>
    </>
  );
};

export default Inventario;
