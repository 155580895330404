import React from 'react';
import { useEffect, useState } from "react";
import CustomMaterialTable from "../../components/MaterialTable";
import Services from "../../services/services";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import { Tooltip, IconButton, Grid } from "@mui/material";

const TablaInventario = ({ productoCreado, handleOpenModalEdit, handleOpenModalHistorial }) => {
  const [data, setData] = useState([]);
  const [buscandoInventario, setBuscandoInventario] = useState(false);
  const inventario = async () => {
    try {
      setBuscandoInventario(true);
      let result = await Services.getInventario({
        busqueda: "",
        filtro: null,
      });
      console.log(result.Inventarios);
      setData(result.Inventarios);
      setBuscandoInventario(false);
    } catch (error) {
      alert("ubo en error");
    }
  };
  const columns = [
    {
      title: "SKU",
      field: "sku",
    },
    {
      title: "Descripcion",
      field: "nombre",
      //minWidth: 170,
    },
    {
      title: "Stock Fisico",
      field: "stockFisico",
    },
    {
      title: "Stock Reservado",
      field: "stockReservado",
    },
    {
      title: "Stock Total",
      field: "stockTotal",
    },
    {
      title: "acciones",
      render: (rowData) => (
        <Grid container>
          <Tooltip title="Editar">
            <IconButton
              aria-label="editar"
              onClick={() => {
                handleOpenModalEdit(rowData.sku);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Historial">
            <IconButton
              aria-label="historial"
              onClick={() => {
                handleOpenModalHistorial(rowData.sku);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
    // {
    //   title: 'Fecha desde',
    //   field: 'date_from',
    //   minWidth: 170,
    //   render: useCallback(
    //     (rowData) => formatDateTimeStamp(rowData.date_from),
    //     []
    //   ),
    // },
  ];
  useEffect(() => {
    inventario();
  }, [productoCreado]);
  return (
    <CustomMaterialTable
      title="Inventario"
      data={buscandoInventario ? [] : data}
      columns={columns}
      style={{ width: "100%", marginTop: "20px" }}
      mensajeDataArrayVacio={
        buscandoInventario
          ? "Buscando inventario"
          : "No hay inventario disponible"
      }
    />
  );
};

export default TablaInventario;
