import React from 'react';
import {
    Grid
} from "@mui/material";
import CustomMaterialTable from "../../../components/MaterialTable";
import { useEffect, useState } from "react";
import Services from "../../../services/services";
import { formatDateString } from "../../../helpers";


const MoverInventario = () => {
    const [listadoDeIngresos, setListadoDeIngresos] = useState([]);
    const [buscandoIngresos, setBuscandoIngresos] = useState(false);

    const [paginacion, setPaginacion] = useState({
        page: 1,
        pageSize: 10,
        total: 0,
    });

    const fetchAllIngresos = async (page, pageSize) => {
        setBuscandoIngresos(true);
        const result = await Services.getAllIngresos({ page, pageSize });
        setListadoDeIngresos(result?.data?.Ingresos);
        setPaginacion((prev) => ({
            ...prev,
            total: result?.data?.total || 0,
        }));
        setBuscandoIngresos(false);
    };

    useEffect(() => {
        fetchAllIngresos();
    }, []);

    const columns = [
        {
            title: "Id",
            field: "IdOrden",
            width: 40,
        },
        {
            title: "Comprobante",
            field: "Comprobante",
            maxWidth: 100,
        },
        {
            title: "Estado",
            field: "Estado",
            maxWidth: 110,
        },
        {
            title: "Fecha de Creacion",
            field: "FechaCreacion",
            minWidth: 150,
            render: (rowData) => formatDateString(rowData["FechaCreacion"]),
        },
        {
            title: "Articulos Recibidos",
            field: "acciones",
            render: (rowData) => (
                <div>
                    <div>
                        SKU: {rowData?.Sku[0]}/{rowData?.Sku[1]}
                    </div>
                    <div>
                        Unidades: {rowData?.Unidades[0]}/{rowData?.Unidades[1]}
                    </div>
                </div>
            ),
        }
    ];

    return (
        <Grid container sx={{
            padding: "40px"
        }}>
            <Grid container item xs={12}>
                <CustomMaterialTable
                    title="Mover Inventario"
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchAllIngresos(query.page + 1, query.pageSize).then(() => {
                                resolve({
                                    data: listadoDeIngresos,
                                    page: paginacion.page - 1,
                                    totalCount: paginacion.total,
                                });
                            });
                        })
                    }
                    columns={columns}
                    style={{ width: "100%", marginTop: "20px" }}
                    mensajeDataArrayVacio={
                        buscandoIngresos
                            ? "Buscando ingresos"
                            : "No hay ingresos disponibles"
                    }
                    options={{
                        sorting: true,
                        search: true,
                        paging: true,
                        pageSize: paginacion.pageSize,
                        debounceInterval: 500,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default MoverInventario;
