import React from 'react';
import CollapseDragable from "./CollapseDragable";
import { useState } from "react";

export default function Sublevel(props) {
  const { sublevel } = props;

  const [sublevelAtributos, setSublevelAtributos] = useState({
    name: sublevel.name,
    height: sublevel.height,
    width: sublevel.width,
    depth: sublevel.depth,
  });

  return (
    <CollapseDragable
      nombre={`Subnivel ${sublevel.id}`}
      item={sublevelAtributos}
      setItem={setSublevelAtributos}
    />
  );
}
