import React from 'react';
import {
  Button,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import ClienteRetira from "./formularios/clienteRetira";
import FletePropio from "./formularios/fletePropio";
import Redespacho from "./formularios/redespacho";
import TransporteRetira from "./formularios/transporteRetira";
import Buspack from "./formularios/buspack";
import Services from "../../../services/services";
import { Link } from "react-router-dom";
import * as styles from "./styles";
import { validarDNI, validarMail, validarTelefono } from "../../../helpers";

export function validarFormularioEgreso(nombre, apellido, dni, telefono, comprobante, email, setError) {
  let isValid = true;

  let error = {
    nombre: "",
    apellido: "",
    dni: "",
    telefono: "",
    comprobante: "",
    email: "",
    observaciones: "",
  };

  if (!email) {
    error.email = "Campo obligatorio";
    isValid = false;
  }

  if (!validarMail(email)) {
    error.email = "Email inválido";
    isValid = false;
  }

  if (!nombre) {
    error.nombre = "Campo obligatorio";
    isValid = false;
  }

  if (!apellido) {
    error.apellido = "Campo obligatorio";
    isValid = false;
  }

  if (!dni) {
    error.dni = "Campo obligatorio";
    isValid = false;
  }

  if (!validarDNI(dni)) {
    error.dni = "DNI inválido";
    isValid = false;
  }

  if (!telefono) {
    error.telefono = "Campo obligatorio";
    isValid = false;
  }

  if (!validarTelefono(telefono)) {
    error.telefono = "Teléfono inválido";
    isValid = false;
  }

  if (!comprobante) {
    error.comprobante = "Campo obligatorio";
    isValid = false;
  }

  if (!isValid) setError(error);
  return isValid;
}

const CrearEgreso = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [tiposDeEgreso, setTiposDeEgreso] = useState([]);

  // const handleChange = (event) => {
  //   setSelectedValue(event?.target.value);
  // };

  useEffect(() => {
    fetchTypesEgresos();
  }, []);

  const fetchTypesEgresos = async () => {
    const result = await Services.getTypesEgresos();
    setTiposDeEgreso(result.data.TiposEgreso);
    console.log("resultado de tipos de egreso", result);
  };

  const returnForm = () => {
    if (selectedValue === 1) {
      return <ClienteRetira tipoDeEgreso={selectedValue} />;
    }
    if (selectedValue === 2) {
      return <FletePropio tipoDeEgreso={selectedValue} />;
    }
    if (selectedValue === 3) {
      return <Redespacho tipoDeEgreso={selectedValue} />;
    }
    if (selectedValue === 4) {
      return <TransporteRetira tipoDeEgreso={selectedValue} />;
    }

    if (selectedValue === 5) {
      return <Buspack tipoDeEgreso={selectedValue} />;
    }
    return null;
  };
  return (
    <styles.RootContainer>
      <Grid
        container
        item
        xs={8}
        justifyContent={"center"}
        style={{ alignContent: "baseline" }}
      >
        <Grid
          container
          item
          xs={12}
          style={{ marginTop: "2vh", marginBottom: "2vh" }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid xs={8} style={{ textAlign: "start" }}>
            <h1 style={{ color: "#1976d2" }}>Nuevo Egreso</h1>
          </Grid>
          <Grid xs={4} style={{ textAlign: "end" }}>
            <Link to="/listado-egresos">
              <Button variant="contained" color="primary">
                Listado Egresos
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {tiposDeEgreso.length > 0 && tiposDeEgreso.map((element) => {
            return (
              <Button
                variant="contained"
                sx={{ marginRight: "1vh", backgroundColor: selectedValue === element.id ? "#1976d2" : "#60a1e0" }}
                value={element.id}
                onClick={() => setSelectedValue(element.id)}
              >
                {element.nombre}
              </Button>
            );
          })
          }

          {/*<FormControl sx={{ width: { xs: "80%", md: "40%" } }}>
            <InputLabel id="select-label">Seleccione una opcion</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={selectedValue}
              label="Seleccione una opcion"
              onChange={handleChange}
            >
              <MenuItem value="">Seleccione una opcion</MenuItem>
              {tiposDeEgreso.map((element) => {
                return <MenuItem value={element.id}>{element.nombre}</MenuItem>;
              })}
              { <MenuItem value="cliente">Cliente Retira</MenuItem>
            <MenuItem value="flete">Flete Propio</MenuItem>
            <MenuItem value="redespacho">Redespacho</MenuItem>
            <MenuItem value="transporte">Transporte retira</MenuItem> }
            </Select>
          </FormControl>
          */}
        </Grid>
        <Grid item xs={12}>
          {returnForm()}
        </Grid>
      </Grid>
    </styles.RootContainer>
  );
};

export default CrearEgreso;
