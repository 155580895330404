import React from 'react';
import { Box } from "@mui/material";
import CollapseDragable from "./CollapseDragable";
import DragDrop from "./DragDrop";
import { useEffect, useState } from "react";
import Sublevels from "./Sublevels";

export default function Levels(props) {
  const { rack } = props;

  const [levels, setLevels] = useState([]);
  const [rackAtributos, setRackAtributos] = useState({
    name: rack.name,
    height: rack.height,
    width: rack.width,
    depth: rack.depth,
  });

  useEffect(() => {
    setLevels(
      rack.levels.map((level) => {
        return {
          id: level.id.toString(),
          content: <Sublevels level={level} />,
        };
      })
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <CollapseDragable
        content={<DragDrop items={levels} setItems={setLevels} />}
        nombre={`Rack ${rack.id}`}
        item={rackAtributos}
        setItem={setRackAtributos}
      />
    </Box>
  );
}
