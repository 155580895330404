import React from 'react';
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Home() {

    const navigate = useNavigate();

    function handleIngresos() {
        navigate('/operario/ingreso');
    }

    const sxButton = {
        width: '100%',
        padding: 2,
        fontSize: 20,
        fontWeight: 'bold',
        borderRadius: 2,
    }

    return (
        <Grid container sx={{
            alignItems: 'center',
            justifyContent: 'center',
            paddingY: 10,
            height: '80vh',
        }}>
            <Grid item container xs={10} sx={{
                gap: 10,
                height: '100%',
            }}>
                <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={handleIngresos}
                    sx={sxButton}
                >
                    Ingreso
                </Button>
                <Button variant="contained" fullWidth color="primary" sx={sxButton}>Egreso</Button>
            </Grid>
        </Grid >
    );
}