import React from 'react';
import { Box } from "@mui/material";
import CollapseDragable from "./CollapseDragable";
import DragDrop from "./DragDrop";
import { useEffect, useState } from "react";
import Sublevel from "./Sublevel";

export default function Sublevels(props) {
  const { level } = props;

  const [sublevels, setSublevels] = useState([]);
  const [levelAtributos, setLevelAtributos] = useState({
    name: level.name,
    height: level.height,
    width: level.width,
    depth: level.depth,
  });

  useEffect(() => {
    setSublevels(
      level.sublevels.map((sublevel) => {
        return {
          id: sublevel.id.toString(),
          content: <Sublevel sublevel={sublevel} />,
        };
      })
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <CollapseDragable
        content={<DragDrop items={sublevels} setItems={setSublevels} />}
        nombre={`Nivel ${level.id}`}
        item={levelAtributos}
        setItem={setLevelAtributos}
      />
    </Box>
  );
}
