import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { IconButton } from "@mui/material";

export default function DragDrop(props) {
  const { items, setItems } = props;

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const itemsCopy = [...items];
    const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
    itemsCopy.splice(result.destination.index, 0, reorderedItem);

    setItems(itemsCopy);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <List {...provided.droppableProps} ref={provided.innerRef}>
            {items.map(({ id, content }, index) => (
              <Draggable key={id} draggableId={id} index={index}>
                {(provided) => (
                  <ListItem
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    style={{
                      ...provided.draggableProps.style,
                      alignItems: "center",
                      border: "1px solid #ccc",
                      gap: "10px",
                      margin: "10px 0",
                      padding: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <div {...provided.dragHandleProps}>
                      <IconButton>
                        <DragIndicatorIcon />
                      </IconButton>
                    </div>
                    {content}
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}
