import React from 'react';
import { Box } from "@mui/material";
import CollapseDragable from "./CollapseDragable";
import DragDrop from "./DragDrop";
import { useEffect, useState } from "react";
import Racks from "./Rack";

export default function Halls(props) {
  const { zone, setZone } = props;

  const [halls, setHalls] = useState([]);
  const [zoneAtributos, setZoneAtributos] = useState({
    name: zone.name,
    height: zone.height,
    width: zone.width,
    depth: zone.depth,
  });

  useEffect(() => {
    setHalls(
      zone.halls.map((hall) => {
        return {
          id: hall.id.toString(),
          content: <Racks hall={hall} />,
        };
      })
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <CollapseDragable
        content={<DragDrop items={halls} setItems={setHalls} />}
        nombre={`Zona ${zone.id}`}
        item={zoneAtributos}
        setItem={setZoneAtributos}
      />
    </Box>
  );
}
