import client from "../client/client";

//*******Usuarios*******//

const postNuevoUsuario = async (data) => {
  let resp = client.post("users/register/", data);

  return await resp;
};

const postUpdateUsuario = async (data) => {
  let resp = client.put("users/register/", data);
  return await resp;
};
const getAllUsuarios = async () => {
  let resp = client.get("users/register/", {
    sku: "",
  });

  return resp;
};

const deleteUsuario = async (data) => {
  let resp = client.delete("users/register/", { data });
  return await resp;
};

const getdepositos = async () => {
  try {
    const resp = await client.get(`users/depositos/`);
    return resp;
  } catch (error) {
    console.error("Error al obtener productos por SKU:", error);
    throw error;
  }
};
let Usuarios = {
  postNuevoUsuario,
  getAllUsuarios,
  deleteUsuario,
  postUpdateUsuario,
  getdepositos,
};
export default Usuarios;
