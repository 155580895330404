import React from 'react';
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Ingreso() {
    const [codigo, setCodigo] = useState('')
    const navigate = useNavigate();

    function handleIngresar() {
        navigate('/operario/detalle?id=' + codigo);
    }

    function handleRecibir() {
        navigate('/operario/recibir?id=' + codigo);
    }

    return (
        <Grid container sx={{
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 2,
        }}>
            <Grid item container xs={10} sx={{
                gap: 2,
            }}>
                <Typography variant="h3">Ingreso</Typography>
                <Typography variant="h5">
                    Escanee el codigo de barra
                </Typography>
                <TextField
                    label="Codigo de barra"
                    variant="outlined"
                    fullWidth
                    value={codigo}
                    onChange={(e) => setCodigo(e.target.value)}
                />
                <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={handleIngresar}
                >
                    Ingresar
                </Button>
                <Button
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={handleRecibir}
                >
                    Recibir
                </Button>
            </Grid>
        </Grid>
    )
}