// LoginForm.js
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth";
import { Container, CssBaseline, Paper } from "@mui/material";
import Logo from "./logo";
import CircularProgress from "@mui/material/CircularProgress";
const LoginForm = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);

  const handleLogin = async (e) => {
    setLoadingLogin(true);
    e.preventDefault();

    try {
      let login = await AuthService.singIn({
        name: user,
        password: password,
      });

      if (login.status === 200 && login.data.token) {
        navigate("/home");
      } else {
        alert("fallo en el login");
      }
    } catch {
      console.log("usuario o contraseña incorrecta");
      alert("fallo en el login");
    }
    setLoadingLogin(false);
  };

  useEffect(() => {
    const handleEnter = (e) => {
      if (e.key === "Enter" && user && password && !loadingLogin) {
        handleLogin(e);
      }
    }

    document.addEventListener("keydown", handleEnter);

    return () => {
      document.removeEventListener("keydown", handleEnter);
    }
  }, [user, password, loadingLogin,handleLogin]);

  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper
        elevation={3}
        sx={{
          marginTop: "3rem",
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#1E1E1EFF",
        }}
      >
        <Logo />
        <div>
          <TextField
            label="Usuario"
            variant="outlined"
            fullWidth
            margin="normal"
            value={user}
            onChange={(e) => {
              setUser(e.target.value);
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
              },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              style: { color: "white" },
            }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
              },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              style: { color: "white" },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
            style={{ height: '40px' }}
          >
            {loadingLogin ? <CircularProgress size={25} title="Cargando" color="inherit" /> : "Login"}
          </Button>
          <p style={{ color: "white" }}>version 0.8</p>
        </div>
      </Paper>
    </Container>
  );
};

export default LoginForm;
