import React from 'react';
import { Box } from "@mui/material";
import CollapseDragable from "./CollapseDragable";
import DragDrop from "./DragDrop";
import { useEffect, useState } from "react";
import Halls from "./Halls";

export default function Zones(props) {
  const { almacen } = props;

  const [zones, setZones] = useState([]);
  const [almacenAtributos, setAlmacenAtributos] = useState({
    name: almacen.name,
    height: almacen.height,
    width: almacen.width,
    depth: almacen.depth,
  });

  useEffect(() => {
    setZones(
      almacen.zones.map((zone) => {
        return {
          id: zone.id.toString(),
          content: <Halls zone={zone} />,
        };
      })
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <CollapseDragable
        content={<DragDrop items={zones} setItems={setZones} />}
        nombre={`Almacen ${almacen.id}`}
        item={almacenAtributos}
        setItem={setAlmacenAtributos}
      />
    </Box>
  );
}
