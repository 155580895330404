import { Grid, Typography } from "@mui/material";
import React from "react";

const NoPermitido = () => {
  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} sx={{ marginTop: "4vh" }}>
        <Typography variant="h5">Lo sentimos</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} sx={{ marginTop: "3vh" }}>
          <Typography variant="h6">
            Usted no tiene permiso para acceder a esta sección
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NoPermitido;
