import client from "../client/client";

//*******PRODUCTOS*******//

const postProductos = async (data) => {
  try {
    let resp = await client.post("productos/abm_producto_simple/", data);
    console.log("postProductos", resp);
    return resp;
  } catch (error) {
    console.error("Error al crear productos:", error);
    throw error;
  }
};

const putProductos = async (data) => {
  try {
    let resp = await client.put("productos/abm_producto_simple/", data);
    console.log("putProductos", resp);
    return resp;
  } catch (error) {
    console.error("Error al crear productos:", error);
    throw error;
  }
};

const getProductosBySku = async (busqueda) => {
  try {
    const resp = await client.get(
      `productos/abm_producto_simple/`,
      { sku: busqueda }
    );
    return resp;
  } catch (error) {
    console.error("Error al obtener productos por SKU:", error);
    throw error;
  }
};

// ******* INGRESOS ******* //

const postCrearIngreso = async (data) => {
  try {
    const resp = await client.post("productos/ingresos/", data);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};

const getAllIngresos = async (paginacion) => {
  try {
    const resp = await client.get("productos/ingresos/", { params: paginacion });
    return resp;
  } catch (error) {
    console.error("Error al obtener todos los ingresos:", error);
    throw error;
  }
};

const getIngresoById = async (id) => {
  try {
    const resp = await client.get(`productos/detallesIngreso/?busqueda=${id}`);
    return resp;
  } catch (error) {
    console.error("Error al obtener todos los ingresos:", error);
    throw error;
  }
};

const getAllIngresosPendientes = async () => {
  try {
    const resp = await client.get("productos/ingresosPendientes/");
    return resp;
  } catch (error) {
    console.error("Error al obtener todos los ingresos pendientes:", error);
    throw error;
  }
};

const getInventario = async (data) => {
  try {
    let resp = await client.get("productos/inventario/", { params: data });
    return resp.data;
  } catch (error) {
    console.error("Error al obtener productos:", error);
    throw error;
  }
};

const postRegistrarIngreso = async (data) => {
  try {
    const resp = await client.post("productos/registrarIngreso/", data);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};
//*******EGRESOS*******//

const PostCrearEgreso = async (data) => {
  try {
    const resp = await client.post("productos/CrearEgreso/", data);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};

const getAllEgresos = async () => {
  try {
    const resp = await client.get("productos/CrearEgreso/");
    return resp;
  } catch (error) {
    console.error("Error al obtener egresos:", error);
    throw error;
  }
};

const getTypesEgresos = async () => {
  try {
    const resp = await client.get("productos/tiposEgreso/");
    return resp;
  } catch (error) {
    console.error("Error al obtener los tipos de egresos:", error);
    throw error;
  }
};
const PostEnPreparacion = async (data) => {
  try {
    const resp = await client.post(
      "productos/comenzarPreparacionEgreso/",
      data
    );
    return resp;
  } catch (error) {
    console.error("Error al pasar en preparacion:", error);
    throw error;
  }
};
const PostPrepararEgreso = async (data) => {
  try {
    const resp = await client.post("productos/prepararEgreso/", data);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};
const GetPrepararEgreso = async () => {
  try {
    const resp = await client.get("productos/prepararEgreso/");
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};
const PostEgresar = async (data) => {
  try {
    const resp = await client.post("productos/egresar/", data);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};

const deleteEgreso = async (data) => {
  try {
    const resp = await client.delete("productos/CrearEgreso/", { data });
    return resp;
  } catch (error) {
    console.error("Error al eliminar ingreso:", error);
    throw error;
  }
};

const getSeguimiento = async (data) => {
  // parametro hasheado hasta que este el back
  if (data.codigo === "1") return { "desde": "18-02-2024", "hasta": "24-02-2024", "data": [{ "estado": "En preparacion", "fecha": "2021-10-10" }, { "estado": "Preparado", "fecha": "2021-10-10" }, { "estado": "Enviado" }] }
  try {
    const resp = await client.get("productos/seguimiento/", { params: data });
    return resp;
  } catch (error) {
    console.error("Error al obtener productos:", error);
    throw error;
  }
};

const getPerfil = async () => {
  try {
    const resp = await client.get("users/perfil/");
    return resp;
  } catch (error) {
    console.error("Error al obtener perfil:", error);

    return {
      "user": {
        "id": 1,
        "user": "Usuario10",
        "activo": true,
        "fechaCreacion": "2024-03-04T13:34:48.019821Z",
        "rol_id": 3,
        "rol": "Super Usuario",
        "empresa_id": 1,
        "empresa": "Voras"
      },
      "depositos": [
        {
          "id": 2,
          "nombre": "Deposito 2",
          "localidad": "Alicia",
          "provincia": "Córdoba",
          "codigoPostal": "5949",
          "calle": "Entre Rios",
          "numero": "1050",
          "piso": "2",
          "departamento": "5",
          "telefono": "3533456789"
        },
        {
          "id": 1,
          "nombre": "Deposito 1",
          "localidad": "ALICIA",
          "provincia": "Córdoba",
          "codigoPostal": "5949",
          "calle": "Entre Rios",
          "numero": "236",
          "piso": "1",
          "departamento": "2",
          "telefono": "3533353345"
        }
      ]
    }
  }
};

const updatePerfil = async (data) => {
  try {
    const resp = await client.put("users/register/", data);
    return resp;
  } catch (error) {
    console.error("Error al actualizar perfil:", error);
    throw error;
  }
};

const updateDeposito = async (data) => {
  try {
    const resp = await client.put("users/depositos/", data);
    return resp;
  } catch (error) {
    console.error("Error al actualizar deposito:", error);
    throw error;
  }
};

const getHistorial = async (sku) => {
  try {
    const resp = await client.get('productos/movimientos/?busqueda=' + sku);
    return resp;
  } catch (error) {
    console.error("Error al obtener historial:", error);
    throw error;
  }
};


let Services = {
  getHistorial,
  putProductos,
  updateDeposito,
  updatePerfil,
  getPerfil,
  getSeguimiento,
  postProductos,
  getProductosBySku,
  postCrearIngreso,
  getAllIngresos,
  getIngresoById,
  getAllIngresosPendientes,
  getInventario,
  postRegistrarIngreso,
  PostCrearEgreso,
  getAllEgresos,
  PostPrepararEgreso,
  GetPrepararEgreso,
  PostEgresar,
  getTypesEgresos,
  PostEnPreparacion,
  deleteEgreso,
};
export default Services;
