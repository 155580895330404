import { styled } from "@mui/system";

export const RootContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  height: "100vh",
  width: "100%",

  [theme.breakpoints.down("md")]: {
  },
}));

export const StyledBox = styled("div")(({ theme }) => ({
  border: "5px solid transparent",  // Establece el borde predeterminado
  borderRadius: "0",  // Establece el radio predeterminado
  [theme.breakpoints.up("md")]: {
    border: `5px solid #1976d2`,
    borderRadius: "8px",
  },
}));

export const InputGridContainer = styled("div")({
  paddingBottom: '16px',
});

export const InputField = styled("div")({
  width: "80%",
});

export const ButtonsContainer = styled("div")({
  display: 'flex',
  justifyContent: 'space-evenly',
  paddingBottom: '16px',
});

