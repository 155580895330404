import React from 'react';
import { Box } from "@mui/material";
import CollapseDragable from "./CollapseDragable";
import DragDrop from "./DragDrop";
import { useEffect, useState } from "react";
import Levels from "./Levels";

export default function Racks(props) {
  const { hall } = props;

  const [racks, setRacks] = useState([]);
  const [hallAtributos, setHallAtributos] = useState({
    name: hall.name,
    height: hall.height,
    width: hall.width,
    depth: hall.depth,
  });

  useEffect(() => {
    setRacks(
      hall.racks.map((rack) => {
        return {
          id: rack.id.toString(),
          content: <Levels rack={rack} />,
        };
      })
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <CollapseDragable
        content={<DragDrop items={racks} setItems={setRacks} />}
        nombre={`Pasillo ${hall.id}`}
        item={hallAtributos}
        setItem={setHallAtributos}
      />
    </Box>
  );
}
