import React, { useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

export default function CollapseDragable(props) {
  const [collapseOpen, setCollapseOpen] = useState(false);

  const { content, nombre, item, setItem } = props;

  function handleCollapseOpen() {
    setCollapseOpen(!collapseOpen);
  }

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {content && (
          <IconButton onClick={handleCollapseOpen}>
            {collapseOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
        <Typography
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {nombre}
        </Typography>
        <TextField
          label="Nombre"
          variant="outlined"
          fullWidth
          size="small"
          value={item.name}
          onChange={(e) => setItem({ ...item, name: e.target.value })}
        />
        <TextField
          label="Alto"
          variant="outlined"
          fullWidth
          size="small"
          value={item.height}
          onChange={(e) => {
            if (!/^\d*\.?\d*$/.test(e.target.value)) return;
            setItem({ ...item, height: e.target.value });
          }}
        />
        <TextField
          label="Ancho"
          variant="outlined"
          fullWidth
          size="small"
          value={item.width}
          onChange={(e) => {
            if (!/^\d*\.?\d*$/.test(e.target.value)) return;
            setItem({ ...item, width: e.target.value });
          }}
        />
        <TextField
          label="Profundidad"
          variant="outlined"
          fullWidth
          size="small"
          value={item.depth}
          onChange={(e) => {
            if (!/^\d*\.?\d*$/.test(e.target.value)) return;
            setItem({ ...item, depth: e.target.value });
          }}
        />
      </Box>

      {content && <Collapse in={collapseOpen}>{content}</Collapse>}
    </Box>
  );
}
