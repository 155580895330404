import React from 'react';
import { Alert, Button, Grid, Snackbar, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Services from "../../../services/services";
const initialAlert = {
  severity: "success",
  message: "",
};
const CrearProducto = ({ handleCreateProducto, productoSKU }) => {
  const [loading, setLoading] = useState(false);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [open, setOpen] = useState(false);
  const [sku, setSku] = useState("");
  const [nombre, setNombre] = useState("");
  const [ean, setEan] = useState("");
  const [alto, setAlto] = useState("");
  const [largo, setlargo] = useState("");
  const [ancho, setAncho] = useState("");
  const [peso, setPeso] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [error, setError] = useState({
    sku: "",
    nombre: "",
    ean: "",
    alto: "",
    largo: "",
    ancho: "",
    peso: "",
    observaciones: "",
  });
  // ... (resto del código del manejo de errores y funciones de validación)
  useEffect(() => {
    if (productoSKU) {
      setLoading(true);
      Services.getProductosBySku(productoSKU).then((res) => {
        let producto = res.data.Productos[0];
        setSku(String(producto.sku));
        setNombre(producto.nombre);
        setEan(producto.ean);
        setAlto(String(producto.alto));
        setlargo(String(producto.largo));
        setAncho(String(producto.ancho));
        setPeso(String(producto.peso));
        setObservaciones(producto.observaciones);
      }
      ).then(() => {
        setLoading(false);
      });
    }
  }, [productoSKU]);

  function validarFormulario() {
    let isValid = true;
    // Lógica para crear el producto aquí
    let newError = {
      sku: "",
      nombre: "",
      ean: "",
      alto: "",
      largo: "",
      ancho: "",
      peso: "",
      observaciones: "",
    };
    if (sku.trim() === "") {
      newError.sku = "El sku es obligatorio";
      isValid = false;
    }

    if (nombre.trim() === "") {
      newError.nombre = "El nombre es obligatorio";
      isValid = false;
    }

    if (ean.trim() === "") {
      newError.ean = "El ean es obligatorio";
      isValid = false;
    }

    if (largo.trim() === "" || isNaN(largo) || parseInt(largo, 10) <= 0) {
      newError.largo = "El largo debe ser un número positivo";
      isValid = false;
    }
    if (ancho.trim() === "" || isNaN(ancho) || parseInt(ancho, 10) <= 0) {
      newError.ancho = "El ancho debe ser un número positivo";
      isValid = false;
    }
    if (alto.trim() === "" || isNaN(alto) || parseInt(alto, 10) <= 0) {
      newError.alto = "El alto debe ser un número positivo";
      isValid = false;
    }
    if (peso.trim() === "" || isNaN(peso) || parseInt(peso, 10) <= 0) {
      newError.peso = "El peso debe ser un número positivo";
      isValid = false;
    }

    setError(newError);

    // Si hay errores, no realiza la acción de crear el producto
    if (!isValid) {
      return false;
    }

    return true;
  }


  async function handleEditProduct() {
    if (!validarFormulario()) {
      return;
    }
    try {
      setLoading(true);
      let productoNuevo = await Services.putProductos({
        sku,
        nombre,
        ean,
        alto,
        largo,
        ancho,
        peso,
        observaciones,
      });
      if (productoNuevo?.data?.Modificado) {
        setAlertObj({
          severity: "success",
          message: productoNuevo?.data?.Modificado,
        });
        setLoading(false);
        setOpen(true);
        handleCreateProducto();
      } else if (productoNuevo?.data?.Error) {
        setAlertObj({
          severity: "error",
          message: productoNuevo?.data.Error,
        });
        setLoading(false);
        setOpen(true);
      }
    } catch {
      setAlertObj({
        severity: "error",
        message: "Ocurrió un error en la edición",
      });
      setLoading(false);
      setOpen(true);
    }
  }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleCreateProduct = async () => {
    if (!validarFormulario()) {
      return;
    }

    try {
      setLoading(true);
      let productoNuevo = await Services.postProductos({
        sku,
        nombre,
        ean,
        alto,
        largo,
        ancho,
        peso,
        observaciones,
      });
      if (productoNuevo?.data?.Registrado) {
        setAlertObj({
          severity: "success",
          message: productoNuevo?.data?.Registrado,
        });
        setLoading(false);
        setOpen(true);
        clearForm();
        handleCreateProducto();
      } else if (productoNuevo?.data?.Error) {
        setAlertObj({
          severity: "error",
          message: productoNuevo?.data.Error,
        });
        setLoading(false);
        setOpen(true);
      }
    } catch {
      setAlertObj({
        severity: "error",
        message: "Ocurrió un error en la creación",
      });
      setLoading(false);
      setOpen(true);
    }

    // Cierra el modal después de crear el producto
  };
  const clearForm = () => {
    setSku("");
    setNombre("");
    setEan("");
    setAlto("");
    setlargo("");
    setAncho("");
    setPeso("");
    setObservaciones("");
    let newError = {
      sku: "",
      nombre: "",
      ean: "",
      alto: "",
      largo: "",
      ancho: "",
      peso: "",
      observaciones: "",
    };
    setError(newError);
  };

  useEffect(() => { }, []);
  return (
    <Grid
      container
      spacing={2}
      sx={{
        overflowY: "auto",
        maxHeight: { xs: "80vh", sm: "80vh" }, // Ajusta según sea necesario
        paddingY: { xs: 2, sm: 4 },
      }}
    >
      <Grid item xs={12} sm={6}>
        <TextField
          label="SKU"
          variant="outlined"
          disabled={productoSKU}
          fullWidth
          name={"sku"}
          value={sku}
          onChange={(e) => {
            setSku(e.target.value);
            setError({ ...error, sku: "" });
          }}
          error={Boolean(error.sku)}
          helperText={error.sku}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Nombre"
          variant="outlined"
          fullWidth
          value={nombre}
          onChange={(e) => {
            setNombre(e.target.value);
            setError({ ...error, nombre: "" });
          }}
          error={Boolean(error.nombre)}
          helperText={error.nombre}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="EAN"
          variant="outlined"
          disabled={productoSKU}
          fullWidth
          value={ean}
          onChange={(e) => {
            if (e.target.value.length > 14) return;
            if (!/^\d*$/.test(e.target.value)) return;
            setEan(e.target.value);
            setError({ ...error, ean: "" });
          }}
          error={Boolean(error.ean)}
          helperText={error.ean}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Alto en cm"
          variant="outlined"
          fullWidth
          type="number"
          value={alto}
          inputProps={{
            step: 0.1,
          }}
          onChange={(e) => {
            setAlto(e.target.value);
            setError({ ...error, alto: "" });
          }}
          error={Boolean(error.alto)}
          helperText={error.alto}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Largo en cm"
          variant="outlined"
          fullWidth
          type="number"
          value={largo}
          inputProps={{
            step: 0.1,
          }}
          onChange={(e) => {
            setlargo(e.target.value);
            setError({ ...error, largo: "" });
          }}
          error={Boolean(error.largo)}
          helperText={error.largo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Ancho en cm"
          variant="outlined"
          fullWidth
          type="number"
          value={ancho}
          inputProps={{
            step: 0.1,
          }}
          onChange={(e) => {
            setAncho(e.target.value);
            setError({ ...error, ancho: "" });
          }}
          error={Boolean(error.ancho)}
          helperText={error.ancho}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Peso en kg"
          variant="outlined"
          fullWidth
          type="number"
          value={peso}
          inputProps={{
            step: 0.1,
          }}
          onChange={(e) => {
            setPeso(e.target.value);
            setError({ ...error, peso: "" });
          }}
          error={Boolean(error.peso)}
          helperText={error.peso}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Observaciones"
          variant="outlined"
          fullWidth
          multiline
          rows={2}
          value={observaciones}
          onChange={(e) => setObservaciones(e.target.value)}
          error={Boolean(error.observaciones)}
          helperText={error.observaciones}
        />
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="error"
            onClick={clearForm}
            sx={{ marginRight: "2vw" }}
          >
            Limpiar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={loading ? () => { } : productoSKU ? handleEditProduct : handleCreateProduct}
          >
            {loading ? (
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "white" }}
              />
            ) : (
              productoSKU ? "Editar" : "Crear"
            )}
          </Button>
        </div>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertObj?.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default CrearProducto;
