import React from 'react';
//import { useReducer } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DashboardRoutes } from "./DashboardRoutes";
//import { Context, globalState, generalReducer } from '../context'

import PrivateRoute from "../routers/PrivateRoute";
//import { useProvideAuth, AuthContext } from '../hooks/useAuth'
import "../App.css";
import Login from "../views/login/login";
import Prueba from "../views/otraVista/prueba";
import NoPermitido from "../layouts/sinPermiso";
import Seguimiento from "../layouts/seguimiento/index";
import { Widget } from "@typeform/embed-react";
//import AppBarComponent from "../components/AppBarComponenet";

const AppRouter = () => {
  // const [state, dispatch] = useReducer(generalReducer, globalState)

  return (
    //<ProviderAuth>
    // <Context.Provider value={{ state, dispatch }}>
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={
            <Widget id="SOzo8X4d" style={{ width: "100%", height: "100vh" }} />
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/prueba" element={<Prueba />} />
          <Route path={"/seguimiento"} element={<Seguimiento />} />
          <Route path="/no-permitido" element={<NoPermitido />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <DashboardRoutes />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </div>
    //  </Context.Provider>
    //  </ProviderAuth>
  );
};

export default AppRouter;
