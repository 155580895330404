import React from "react";

import { Navigate } from "react-router-dom";
import { TOKEN } from "../utils/constants";

const PrivateRoute = ({ children }) => {
  const token = sessionStorage.getItem(TOKEN);
  return token ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
