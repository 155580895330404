import React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Send } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  p: 4,
  ml: 1,
  mr: 1,
  backgroundColor: "rgb(255, 255, 255)",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
  color: "rgba(0, 0, 0, 0.87)",
};

export default function ModalMobile({
  object,
  label,
  color,
  startIcon,
  accion,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseModal = () => {
    setForm({
      name: object?.user ? object?.user : "",
      password: object?.password ? object?.password : "",
      role:
        object?.role === "Operario"
          ? 1
          : object?.role === "Administrativo"
            ? 2
            : 3,
      id: parseInt(object?.id) ? parseInt(object?.id) : "",
    });

    setOpen(false);
  };

  const [form, setForm] = useState({
    name: object?.user,
    password: object?.password,
    role:
      object?.role === "Operario"
        ? 1
        : object?.role === "Administrativo"
          ? 2
          : 3,
    id: parseInt(object?.id),
  });

  const handleAccion = () => {
    let objectoCreateUpdate = {};
    if (label === "Agregar") {
      objectoCreateUpdate = {
        name: form.name,
        password: form.password,
        rol:
          form.role === 1
            ? "Operario"
            : form.role === 2
              ? "Administrativo"
              : "Super Usuario",
      };
      accion(objectoCreateUpdate);
    } else {
      accion(form);
    }
  };

  const handleChange = (e, v) => {
    let key = e?.target?.name;
    let value = e?.target?.value;

    setForm({ ...form, [key]: value });
  };
  return (
    <div>
      <Button
        color={color}
        startIcon={startIcon}
        variant="outlined"
        size="small"
        onClick={handleOpen}
      >
        {label}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card>
          <Box sx={style}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12}>
                <Typography
                  style={{ textAlign: "center", color: "#1976d2" }}
                  variant="h5"
                >
                  {label}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={form["name"] || ""}
                  error={!form["name"]?.length > 3}
                  name="name"
                  label="Nombre"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={form["password"] || ""}
                  error={!form["password"]?.length > 3}
                  name="password"
                  label="Password"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                  <Select
                    name="role"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={form["role"] || ""}
                    label="Roles"
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Operario</MenuItem>

                    <MenuItem value={2}>Administrativo</MenuItem>
                    <MenuItem value={3}>Super Usuario</MenuItem>
                  </Select>
                </FormControl>

                {/*
                <Autocomplete
                  style={{ width: "266px" }}
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e, v) => handleChange(e, v)}
                  options={roles ? roles : []}
                  value={form["nRol"] || ""}
                  sx={{ width: 237 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Roles" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.label.value === value.value
                  }
                  
                />
                 */}
              </Grid>

              <Grid
                style={{ textAlignLast: "center" }}
                container
                item
                xs={12}
                spacing={3}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={handleCloseModal}
                    variant="outlined"
                    color="error"
                    startIcon={<Delete />}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={handleAccion}
                    variant="outlined"
                    startIcon={<Send />}
                  >
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Modal>
    </div>
  );
}
