import React from 'react';
import { useEffect, useState } from "react";
import Services from "../../../../services/services";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MaterialTable from "@material-table/core";
import { validarFormularioEgreso } from "..";

const initialAlert = {
  severity: "success",
  message: "",
};

const FletePropio = ({ tipoDeEgreso }) => {
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [open, setOpen] = useState(false);
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const [productos, setProductos] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [textoIngresado, setTextoIngresado] = useState("");
  const [productosSeleccionados, setProductosSeleccionados] = useState([]);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [dni, setDni] = useState("");
  const [telefono, setTelefono] = useState("");
  const [comprobante, setComprobante] = useState("");
  const [email, setEmail] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [direccion, setDireccion] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [provincia, setProvincia] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [error, setError] = useState({
    nombre: "",
    apellido: "",
    dni: "",
    telefono: "",
    comprobante: "",
    email: "",
    direccion: "",
    localidad: "",
    provincia: "",
    codigoPostal: "",
    observaciones: "",
  });

  const columns = [
    {
      title: "SKU",
      field: "sku",
      editable: "never",
    },
    {
      title: "descripcion",
      field: "nombre",
      editable: "never",
    },
    {
      title: "Stock Disponible",
      field: "stockDisponible",
      editable: "never",
    },
    {
      title: "Egresar",
      field: "cantidadEgresar",
    },
  ];

  const filterProductsByText = (text) => {
    const filteredProducts = productos.filter(
      (producto) =>
        producto.nombre.toLowerCase().includes(text.toLowerCase()) ||
        producto.sku.toLowerCase().includes(text.toLowerCase())
    );
    setProductosFiltrados(filteredProducts);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setTextoIngresado(inputValue);
  };

  const handleProductSelect = (event, value) => {
    if (value) {
      // Verificar si el producto ya está en la lista
      const existentProduct = productosSeleccionados.find(
        (selectedProduct) => selectedProduct.sku === value.sku
      );

      if (!existentProduct) {
        // Si no existe, agregar el producto con cantidad ingresada null
        setProductosSeleccionados((prevProductos) => [
          { ...value, cantidadEgresar: null },
          ...prevProductos,
        ]);
      }
    }
    setTextoIngresado("");
  };

  const handleSearch = async () => {
    setIsSearching(true);
    try {
      const result = await Services.getProductosBySku("");
      console.log("todos los productos", result);
      if (result.data.Productos) {
        setProductos(result?.data?.Productos);
        setProductosFiltrados(result?.data?.Productos);
      } else {
        setProductos([]);
        setProductosFiltrados([]);
      }
    } catch (error) {
      console.error("Error al cargar productos:", error);
      setProductos([]);
      setProductosFiltrados([]);
    } finally {
      setIsSearching(false);
    }
  };
  const canConfirm = () => {
    const hasSelectedProducts = productosSeleccionados.length > 0;
    const hasInvalidQuantity = productosSeleccionados.some(
      (producto) => !producto.cantidadEgresar || producto.cantidadEgresar <= 0
    );

    const isFormValid =
      nombre &&
      apellido &&
      dni &&
      telefono &&
      comprobante &&
      email &&
      observaciones &&
      direccion &&
      localidad &&
      provincia &&
      codigoPostal;

    return hasSelectedProducts && !hasInvalidQuantity && isFormValid;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const crearEgreso = async () => {
    setIsLoading(true);
    if (!validarFormularioEgreso(nombre, apellido, dni, telefono, comprobante, email, setError)) {
      setIsLoading(false);
      return;
    }

    const detallesEgreso = productosSeleccionados.map((producto) => ({
      sku: producto.sku,
      cantidadAEgresar: parseInt(producto.cantidadEgresar),
    }));
    try {
      let a = {
        nombre,
        apellido,
        dni,
        telefono,
        email,
        comprobante,
        observaciones,
        tipoEgreso: tipoDeEgreso,
        direccion,
        localidad,
        provincia,
        codigoPostal,
        detallesEgreso,
      };
      const resp = await Services.PostCrearEgreso(a);
      //const resp = await Services.GetCrearEgreso();
      if (resp.data.Registrado) {
        setAlertObj({
          severity: "success",
          message: resp?.data.Registrado,
        });

        setOpen(true);
      } else {
        setAlertObj({
          severity: "error",
          message: "Ocurrió un error al crear este egreso",
        });

        setOpen(true);
      }
      setProductosSeleccionados([])
      console.log(resp);
    } catch (error) {
      //console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Cargar todos los productos al iniciar el componente
    handleSearch();
  }, []);

  useEffect(() => {
    // Filtrar productos al cambiar el texto ingresado
    filterProductsByText(textoIngresado);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textoIngresado, productos]);

  useEffect(() => {
    console.log("productosSeleccionados", productosSeleccionados);
  }, [productosSeleccionados]);

  return (
    <Grid container sx={{ marginTop: "4vh" }} justifyContent={"center"}>
      <Grid
        container
        xs={10}
        sx={{ marginBottom: "3vh", justifyContent: "end" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={crearEgreso}
          disabled={!canConfirm() || isLoading}
        >
          {isLoading ? (
            <CircularProgress
              size={20}
              style={{ marginRight: 10, color: "white" }}
            />
          ) : (
            "Crear egreso"
          )}
        </Button>
      </Grid>
      <Grid xs={10} sx={{ marginBottom: "3vh" }}>
        <Accordion>
          <AccordionSummary>
            <Typography>Formulario de Datos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ textAlign: "left", marginBottom: "2vh" }}
              >
                <Typography>Destinatario</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="Nombre"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"nombre"}
                  value={nombre}
                  onChange={(e) => {
                    setNombre(e.target.value);
                    setError({ ...error, nombre: "" });
                  }}
                  error={Boolean(error.nombre)}
                  helperText={error.nombre}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="Apellido"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"apellido"}
                  value={apellido}
                  onChange={(e) => {
                    setApellido(e.target.value);
                    setError({ ...error, apellido: "" });
                  }}
                  error={Boolean(error.apellido)}
                  helperText={error.apellido}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="DNI"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"dni"}
                  value={dni}
                  onChange={(e) => {
                    setDni(() => {
                      if (e.target.value.length > 8) {
                        return dni;
                      }
                      return e.target.value;

                    });
                    setError({ ...error, dni: "" });
                  }}
                  error={Boolean(error.dni)}
                  helperText={error.dni}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="Telefono"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"telefono"}
                  value={telefono}
                  onChange={(e) => {
                    setTelefono(() => {
                      if (e.target.value.length > 15) {
                        return telefono;
                      }
                      return e.target.value;

                    });
                    setError({ ...error, telefono: "" });
                  }}
                  error={Boolean(error.telefono)}
                  helperText={error.telefono}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="Comprobante Asociado"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"comprobante"}
                  value={comprobante}
                  onChange={(e) => {
                    setComprobante(e.target.value);
                    setError({ ...error, comprobante: "" });
                  }}
                  error={Boolean(error.comprobante)}
                  helperText={error.comprobante}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="Email"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"email"}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError({ ...error, email: "" });
                  }}
                  error={Boolean(error.email)}
                  helperText={error.email}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="Direccion"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"direccion"}
                  value={direccion}
                  onChange={(e) => {
                    setDireccion(e.target.value);
                    setError({ ...error, direccion: "" });
                  }}
                  error={Boolean(error.direccion)}
                  helperText={error.direccion}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="Localidad"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"localidad"}
                  value={localidad}
                  onChange={(e) => {
                    setLocalidad(e.target.value);
                    setError({ ...error, localidad: "" });
                  }}
                  error={Boolean(error.localidad)}
                  helperText={error.localidad}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="Provincia"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"provincia"}
                  value={provincia}
                  onChange={(e) => {
                    setProvincia(e.target.value);
                    setError({ ...error, provincia: "" });
                  }}
                  error={Boolean(error.provincia)}
                  helperText={error.provincia}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="Codigo Postal"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"codigoPostal"}
                  value={codigoPostal}
                  onChange={(e) => {
                    setCodigoPostal(e.target.value);
                    setError({ ...error, codigoPostal: "" });
                  }}
                  error={Boolean(error.codigoPostal)}
                  helperText={error.codigoPostal}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: "2vh" }}>
                <TextField
                  label="Observaciones"
                  variant="outlined"
                  sx={{ width: "80%" }}
                  name={"observaciones"}
                  value={observaciones}
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                    setError({ ...error, observaciones: "" });
                  }}
                  error={Boolean(error.observaciones)}
                  helperText={error.observaciones}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid xs={10}>
        <Accordion>
          <AccordionSummary>
            <Typography>Crear Egresos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={8}>
                <Autocomplete
                  options={productosFiltrados}
                  getOptionLabel={(option) =>
                    `sku: ${option.sku}, nombre: ${option.nombre}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Buscar productos"
                      variant="outlined"
                      onChange={(e) => handleInputChange(e)}
                    />
                  )}
                  onChange={(event, value) => handleProductSelect(event, value)}
                  value={null}
                  noOptionsText={
                    isSearching ? "Buscando..." : "No hay resultados"
                  }
                  onInputChange={(event, newInputValue) =>
                    setTextoIngresado(newInputValue)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MaterialTable
                  style={{ width: "100%", marginTop: "20px" }}
                  localization={{
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                    },
                    toolbar: {
                      nRowsSelected: "{0} fila(s) seleccionadas",
                      searchPlaceholder: "Buscar",
                      searchTooltip: "Buscar",
                    },
                    header: {
                      actions: "Actions",
                    },
                    body: {
                      emptyDataSourceMessage: "No hay registros",
                      filterRow: {
                        filterTooltip: "Filter",
                      },
                      editRow: {
                        deleteText:
                          "Esta seguro que desea quitar este producto de la lista",
                      },
                      editTooltip: "Editar",
                      deleteTooltip: "Eliminar",
                      addTooltip: "Agregar Usuario",
                    },
                  }}
                  options={{
                    rowStyle: {
                      overflowWrap: "break-word",
                    },
                    actionsColumnIndex: -1,
                    //paging: false,
                    search: true,
                    pageSize: 5, // Cantidad de filas por página
                    pageSizeOptions: [5, 10, 20], // Opciones para la cantidad de filas por página
                    paginationType: "normal", // Tipo de paginación: 'normal' o 'stepped'
                  }}
                  title="Usuarios"
                  columns={columns}
                  data={productosSeleccionados}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataUpdate = [...productosSeleccionados];
                          const index = oldData.tableData.id;
                          dataUpdate[index] = newData;

                          setProductosSeleccionados(dataUpdate);

                          resolve();
                        }, 500);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          const updatedData = [...productosSeleccionados];
                          const index = updatedData.findIndex(
                            (row) => row.sku === oldData.sku
                          );
                          updatedData.splice(index, 1);
                          setProductosSeleccionados(updatedData);
                          resolve();
                        }, 500);
                      }),
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertObj?.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default FletePropio;
